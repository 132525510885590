import { Config } from "./contexts/ConfigurationContext";

export const configuration: Config = {
  corpusId: "265",
  customerId: "1526022105",
  appTitle: "Search HackerNews",
  apiKey: "zqt_WvU_2YIBRMve7vmQP3h70deqDOgYM0W5VpYeXQ",
  endpoint: "api.vectara.io",
  questions: [
    "What is Gemini-Flash?", "When will GPT-5 be released?", 
    "What is Llama3?", "can I win the Arc-AGI competition?"
  ]
};
